var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-col", { attrs: { md: "12" } }, [
        _c(
          "h1",
          {
            class: [
              `${!_vm.$screen.sm ? "main-page-title" : ""}`,
              { "is-pwa": _vm.$isPwa() },
            ],
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.FormMSG(7, "Carbon removal solution")) +
                "\n\t\t"
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "card px-3 py-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h4", [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.FormMSG(1, "Project current green impact (CO2): ")
                  ) +
                    " " +
                    _vm._s(_vm.portfolios.totalCO21)
                ),
              ]),
            ]),
            _c("p", [
              _c("b", [_vm._v(_vm._s(_vm.FormMSG(2, "Remove ")))]),
              _vm._v(
                _vm._s(
                  _vm.FormMSG(
                    3,
                    "your residual footprint with carbon removal solutions selected & managed by our partner Klimate."
                  )
                ) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                class: [
                  `${
                    !_vm.$screen.md
                      ? "justify-content-around mx-2"
                      : "d-flex justify-content-around mx-2"
                  }`,
                ],
              },
              _vm._l(_vm.portfolios.porfolio, function (portfolio) {
                return _c(
                  "b-col",
                  {
                    key: portfolio.id,
                    class: [`${!_vm.$screen.md ? "mb-3" : ""}`],
                    attrs: { cols: `${!_vm.$screen.md ? "12" : "3"}` },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border rounded-top rounded-bottom border-primary",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c("div", { staticClass: "pt-4 px-2" }, [
                            _c("div", { staticClass: "mb-2" }, [
                              _c("img", {
                                staticClass: "w-100 imgHeight",
                                attrs: {
                                  src:
                                    portfolio.imageXid ||
                                    "https://pocketsolutionsltd.files.wordpress.com/2012/10/grey-landscape-background2.png",
                                  alt: "",
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-left cardTextBody w-90 m-auto",
                              },
                              [
                                _c("h5", [
                                  _c("b", [_vm._v(_vm._s(portfolio.name))]),
                                ]),
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(portfolio.description),
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "text-right pr-3",
                              staticStyle: {
                                background: "#8080802b",
                                padding: "10px",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-decoration-none",
                                  attrs: {
                                    to: `/carbon-removal/${portfolio.id}`,
                                  },
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticStyle: {
                                        color: "black",
                                        margin: "0px",
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _c("i", {
                                          staticClass:
                                            "icon-eye font-weight-bold",
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.FormMSG(4, "  View Details")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "cardFooter rounded-bottom px-2 w-100",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(5, "Cost: ")) +
                                  _vm._s(portfolio.pricePerTon) +
                                  _vm._s(_vm.currency) +
                                  "/ton"
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary w-100 mt-3",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleModal(portfolio)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(6, "Subscribe")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
          ]),
          _c(
            "b-modal",
            {
              ref: "modal",
              attrs: {
                "header-class": "header-class-modal-doc-package",
                size: "lg",
                "hide-footer": "",
                title: this.FormMSG(14, "Carbon removal subscription"),
                centered: "",
              },
              model: {
                value: _vm.isOpen,
                callback: function ($$v) {
                  _vm.isOpen = $$v
                },
                expression: "isOpen",
              },
            },
            [
              _c("b-container", { staticClass: "overflow-hidden" }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("b-row", [
                          _c("div", { staticClass: "text-left" }, [
                            _c("h5", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(
                                      1,
                                      "Project current green impact (CO2): "
                                    )
                                  ) +
                                    " " +
                                    _vm._s(_vm.portfolios.totalCO21)
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "modalody" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    class: [
                                      `${
                                        !_vm.$screen.md ? "px-0" : "px-0 mr-5"
                                      }`,
                                    ],
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "solution" } },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    10,
                                                    "Choosen solution"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "solution",
                                            placeholder: _vm.FormMSG(
                                              122,
                                              "Certified nature based solutions"
                                            ),
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.choosenSolution,
                                            callback: function ($$v) {
                                              _vm.choosenSolution = $$v
                                            },
                                            expression: "choosenSolution",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "px-0",
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "cost" } },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    11,
                                                    "Solution cost"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "cost",
                                            placeholder: _vm.FormMSG(
                                              13,
                                              "15€/ton"
                                            ),
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.solutionCost,
                                            callback: function ($$v) {
                                              _vm.solutionCost = $$v
                                            },
                                            expression: "solutionCost",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    class: [
                                      `${
                                        !_vm.$screen.md ? "px-0" : "px-0 mr-5"
                                      }`,
                                    ],
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "desire" } },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(
                                                    15,
                                                    "Number of ton desired*"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("b-form-input", {
                                          attrs: {
                                            step: "0.01",
                                            state: _vm.isValid,
                                            max: _vm.portfolios.totalCO2 / 1000,
                                            min: "0",
                                            type: "number",
                                            placeholder: _vm.FormMSG(
                                              15,
                                              "Number of ton desired*"
                                            ),
                                          },
                                          on: { update: _vm.getValue },
                                          model: {
                                            value: _vm.desiredValue,
                                            callback: function ($$v) {
                                              _vm.desiredValue = $$v
                                            },
                                            expression: "desiredValue",
                                          },
                                        }),
                                        !_vm.isValid
                                          ? _c(
                                              "small",
                                              { staticClass: "error" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      100,
                                                      "Invalid Number (only 2 decimal allowed)"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    !_vm.$screen.md
                                      ? _c("p", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                this.FormMSG(
                                                  18,
                                                  "The quantity desired is set by default with the number of Ton of your project."
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                this.FormMSG(
                                                  19,
                                                  "You can change it by the number of ton you want to compensate."
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "px-0",
                                    attrs: {
                                      sm: "12",
                                      md: "12",
                                      lg: "4",
                                      xl: "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "total" } },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  this.FormMSG(16, "Total cost")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                id: "total",
                                                placeholder: _vm.FormMSG(
                                                  16,
                                                  "Total cost"
                                                ),
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.totalAmount,
                                                callback: function ($$v) {
                                                  _vm.totalAmount = $$v
                                                },
                                                expression: "totalAmount",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              [
                                                _c("b-input-group-text", [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(_vm.currency)
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("b-row", [
                              _vm.$screen.md
                                ? _c("p", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          this.FormMSG(
                                            18,
                                            "The quantity desired is set by default with the number of Ton of your project."
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          this.FormMSG(
                                            19,
                                            "You can change it by the number of ton you want to compensate."
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ])
                                : _vm._e(),
                              _c("p", [
                                _c("b", [
                                  _vm._v(_vm._s(this.FormMSG(20, "Note"))),
                                ]),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      this.FormMSG(
                                        21,
                                        "After confirming your subscription, you will receive an offer by email with additional instructions."
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      this.FormMSG(
                                        22,
                                        "Your carbon removal certificate will be sent to you at the end of the process."
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "flex" },
                          [
                            _c("b-col", {
                              staticClass: "px-0",
                              attrs: { sm: "0", md: "0", lg: "4", xl: "4" },
                            }),
                            _c(
                              "b-col",
                              {
                                staticClass: "px-0 text-center w-50",
                                attrs: { lg: "4", xl: "4", md: "6" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-75",
                                    attrs: { variant: "outline-primary" },
                                    on: { click: _vm.onCancel },
                                  },
                                  [_vm._v(_vm._s(this.FormMSG(23, "Cancel")))]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "px-0 text-center w-50",
                                attrs: { lg: "4", xl: "4", md: "6" },
                              },
                              [
                                _vm.$screen.md
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "w-75",
                                        attrs: { disabled: !_vm.isValid },
                                        on: { click: _vm.onSubmit },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.FormMSG(
                                              24,
                                              "Confirm subscription"
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "b-button",
                                      {
                                        staticClass: "w-75",
                                        attrs: { disabled: !_vm.isValid },
                                        on: { click: _vm.onSubmit },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(this.FormMSG(254, "Confirm"))
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }